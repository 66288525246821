import { Box, Stack, Typography } from '@mui/material';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-material-ui-components';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Layout from '@gaia/Layout';

import CallToAction from '../components/GetInvolved/CallToAction';
import VolunteerList from '../components/GetInvolved/VolunteerList';
import TestOurAppForm from '../components/TestOurAppForm';
import SEO from '../components/seo';

import donationJar from '../images/donation-jar.svg';
import raisedHands from '../images/raised-hands.svg';
import usingAMobilePhone from '../images/using-a-mobile-phone.svg';

const GetInvolved = () => (
    <Layout>
        <SEO title="Get Involved" />
        <Stack
            flexDirection="row"
            gap={7}
            justifyContent="space-between"
            sx={{ mb: { xs: 5, sm: 6 } }}
        >
            <Box sx={{ flexBasis: { xs: '100%', sm: '50%' } }}>
                <Typography variant="h1" sx={{ color: 'primary.main', mb: 4 }}>
                    Join the Earth Hero Team
                </Typography>
                <Typography paragraph variant="subtitle1">
                    Earth Hero is a group of volunteers who came together to do our part to address
                    the climate crisis. We contribute in different ways with our own unique
                    interests—yet share a common cause.
                </Typography>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <StaticImage
                    src="../images/getting-involved.svg"
                    alt="People around a whiteboard adding ideas to it"
                />
            </Box>
        </Stack>
        <Box component="section" sx={{ mb: { xs: 5, sm: 11 } }}>
            <Typography component="h1" variant="h2" sx={{ mb: 6, textAlign: 'center' }}>
                Ways to Get Involved
            </Typography>
            <Stack
                sx={{
                    flexDirection: { sm: 'row' },
                    justifyContent: 'center',
                    gap: { xs: 5, sm: 7 },
                }}
            >
                <CallToAction
                    title="Volunteer"
                    image={raisedHands}
                    imageAlt="Raised hands illustration"
                >
                    Earth Hero is powered by a global network of volunteers in a diverse range of
                    roles. <Link href="#volunteer">If you have 20 hours to volunteer a month</Link>,
                    we’d like to hear from you.
                </CallToAction>
                <CallToAction title="Donate" image={donationJar} imageAlt="Donate jar illustration">
                    If you aren’t able to commit to volunteering but still want to support us,
                    consider <Link to="/donate">making a donation</Link>. Your financial support
                    allows Earth Hero to remain free for everyone.
                </CallToAction>

                <CallToAction
                    title="Test Our App"
                    image={usingAMobilePhone}
                    imageAlt="Hands using a Mobile Phone illustration"
                >
                    We are frequently updating our app with new features and languages. Help us keep
                    progress moving forward by{' '}
                    <Link href="#testOurApp">testing our upcoming app releases</Link>.
                </CallToAction>
            </Stack>
        </Box>
        <Box component="section" sx={{ mb: { xs: 5, sm: 11 } }}>
            <Typography id="volunteer" component="h1" variant="h3" sx={{ mb: { xs: 1, sm: 3 } }}>
                Volunteer
            </Typography>
            <Typography paragraph sx={{ width: 560, maxWidth: '100%', mb: { xs: 3, sm: 4 } }}>
                As a team of volunteers, we follow the philosophy that many hands make light work.
                Earth Hero has many areas you can contribute to. Browse the positions we are
                actively recruiting for on{' '}
                <Link
                    href="https://climatebase.org/company/1130951/earth-hero"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                >
                    Climatebase
                </Link>{' '}
                or contact us below if you support in one of these areas:
            </Typography>
            <Stack sx={{ flexDirection: { sm: 'row' }, gap: { sm: 3 }, mb: { xs: 3, sm: 4 } }}>
                <VolunteerList
                    title="Development"
                    items={[
                        'Mobile Development (Swift and Kotlin)',
                        'Website Dev (Javascript / React / Gatsby)',
                    ]}
                />
                <VolunteerList
                    title="Design"
                    items={['Art or Graphic Design', 'UX/UI Design', 'Project Management']}
                />
                <VolunteerList
                    title="Content and Communications"
                    items={[
                        'Writing Actions, Articles, etc.',
                        'Social Media Posting',
                        'Translations',
                    ]}
                />
                <VolunteerList
                    title="Research and Data"
                    items={['CO₂e Emissions Modeling', 'Research']}
                />
            </Stack>
            <Typography variant="subtitle2">
                If you are interested, send a message through our{' '}
                <Link to="/contact">contact form</Link>.
            </Typography>
        </Box>

        <Box
            component="section"
            sx={{ px: { xs: 2, sm: 6, md: 12 }, py: { xs: 3, sm: 7 }, mx: { xs: -2, sm: 0 } }}
            bgcolor="grey.50"
        >
            <Typography id="testOurApp" component="h1" variant="h3" sx={{ mb: { xs: 2, sm: 3 } }}>
                Test Our App
            </Typography>
            <Typography paragraph sx={{ mb: { xs: 4, sm: 5 } }}>
                At the heart of Earth Hero is our app. As we release new features, we need help
                ensuring they work for everyone. Sign up below to become a tester. If you are
                interested in volunteering, reach out to us through our{' '}
                <Link to="/contact">contact form</Link>.
            </Typography>
            <TestOurAppForm />
        </Box>
    </Layout>
);

export default GetInvolved;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
