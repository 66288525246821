import React from 'react';
import { Typography, Box, List } from '@mui/material';

import ListItem from '@gaia/components/ListItem';

interface VolunteerListProps {
    title: string;
    items: string[];
}

const VolunteerList = ({ title, items }: VolunteerListProps) => (
    <Box sx={{ flexBasis: 0, flexGrow: 1, flexShrink: 1 }}>
        <Typography variant="h6" sx={{ pt: 1, pb: 3, display: { xs: 'none', sm: 'block' } }}>
            {title}
        </Typography>
        <List sx={{ py: 0 }}>
            {items.map(item => (
                <ListItem key={item} text={item} />
            ))}
        </List>
    </Box>
);

export default VolunteerList;
