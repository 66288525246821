import React, { useState } from 'react';
import { Box, Stack, MenuItem, Button, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import InputField from '@gaia/components/InputField';

const schema = yup.object({
    email: yup
        .string()
        .required()
        .email()
        .default(''),
    name: yup
        .string()
        .required()
        .default(''),
    device: yup
        .string()
        .required()
        .default(''),
    usage: yup
        .string()
        .required()
        .default(''),
    frequency: yup
        .string()
        .required()
        .default(''),
    study: yup
        .string()
        .required()
        .default(''),
    age: yup
        .string()
        .required()
        .default(''),
    country: yup
        .string()
        .required()
        .default(''),
    forBots: yup.string().default(''),
});

type FormData = yup.InferType<typeof schema>;

const deviceOptions = [
    { value: 'Android', label: 'Android' },
    { value: 'iPhone', label: 'iPhone' },
];

const ageOptions = [
    { value: '15-19', label: '15-19' },
    { value: '20-24', label: '20-24' },
    { value: '25-29', label: '25-29' },
    { value: '30-39', label: '30-39' },
    { value: '40-49', label: '40-49' },
    { value: '50-59', label: '50-59' },
    { value: '60+', label: '60+' },
];

const usageOptions = [
    { value: "I didn't download it", label: "I didn't download it" },
    { value: 'I did not use it', label: 'I did not use it' },
    { value: 'Less than once a month', label: 'Less than once a month' },
    { value: 'Once or twice a month', label: 'Once or twice a month' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Daily', label: 'Daily' },
];

const frequencyOptions = [
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Few times a year', label: 'Few times a year' },
];

const studyOptions = [
    { value: 'Interview', label: 'Interview (speaking)' },
    { value: 'Online Test', label: 'Online Test (reading/writing)' },
    { value: 'Both', label: 'Both' },
];

const TestOurAppForm = () => {
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');

    const { control, register, handleSubmit, reset } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.getDefault(),
    });

    const handleSubmission = async (formData: FormData) => {
        try {
            setFormError('');
            setFormSuccess('');
            const response = await fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                    ...formData,
                    'form-name': 'testerSignup',
                }).toString(),
            });

            if (response.ok) {
                reset();
                setFormSuccess(
                    'Thanks for getting in touch! We will reach out when we conduct our next study.'
                );
            } else {
                throw new Error(response.statusText);
            }
        } catch (e) {
            console.log('Failed to submit form.', e.message);
            setFormError('There was a problem submitting your form. Please try again.');
        }
    };

    return (
        <form
            name="testerSignup"
            data-netlify="true"
            netlify-honeypot="forBots"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(handleSubmission)}
        >
            <Stack
                flexWrap="wrap"
                sx={{
                    gap: { xs: 2, sm: 3 },
                    flexDirection: { sm: 'row' },
                    mb: { xs: 4, sm: 5 },
                    '& .MuiTextField-root': { flex: '1 0 35%' },
                }}
            >
                <input type="hidden" name="form-name" value="testerSignup" />
                <div hidden>
                    <label>
                        <input {...register('forBots')} />
                    </label>
                </div>

                <InputField name="name" label="Name" control={control} schema={schema} />
                <InputField name="email" label="Email address" control={control} schema={schema} />
                <InputField
                    name="device"
                    label="Your mobile device"
                    control={control}
                    schema={schema}
                    select
                >
                    {deviceOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </InputField>

                <InputField
                    name="usage"
                    label="How often did you use the app in the last 3 months?"
                    control={control}
                    schema={schema}
                    select
                >
                    {usageOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </InputField>

                <InputField
                    name="frequency"
                    label="How frequently can you participate in studies"
                    control={control}
                    schema={schema}
                    select
                >
                    {frequencyOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </InputField>

                <InputField
                    name="study"
                    label="What kind of studies are you comfortable taking part in English?"
                    control={control}
                    schema={schema}
                    select
                >
                    {studyOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </InputField>

                <InputField
                    name="age"
                    label="Age group"
                    control={control}
                    schema={schema}
                    select
                    sx={{
                        //  Fixes the height of the drop-down to the same height as the textbox, to the right of it
                        '& .MuiSelect-select': {
                            height: '1.4375em',
                        },
                    }}
                >
                    {ageOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </InputField>

                <InputField name="country" label="Country" control={control} schema={schema} />
            </Stack>
            <Alert
                variant="filled"
                severity="error"
                sx={{ display: formError.length > 0 ? 'flex' : 'none' }}
            >
                {formError}
            </Alert>
            <Alert
                variant="filled"
                severity="success"
                sx={{ display: formSuccess.length > 0 ? 'flex' : 'none' }}
            >
                {formSuccess}
            </Alert>
            <Box display="flex" justifyContent="end" sx={{ mt: { xs: 4, sm: 5 } }}>
                <Button variant="contained" type="submit" sx={{ width: { xs: '100%', sm: 240 } }}>
                    Sign Up
                </Button>
            </Box>
        </form>
    );
};

export default TestOurAppForm;
