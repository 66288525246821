import React, { ReactElement } from 'react';
import { Typography, Box } from '@mui/material';

interface CallToActionProps {
    title: string;
    image: string;
    imageAlt: string;
    children: ReactElement;
}

const CallToAction = ({ title, image, children, imageAlt = '' }: CallToActionProps) => (
    <Box textAlign="center">
        <Box
            component="img"
            src={image}
            alt={imageAlt}
            sx={{ display: 'inline-block', marginBottom: { xs: 1, sm: 3 } }}
            loading="lazy"
            width={170}
            height={170}
        />
        <Typography variant="h4" sx={{ mb: 1 }}>
            {title}
        </Typography>
        <Typography variant="body1">{children}</Typography>
    </Box>
);

export default CallToAction;
